import { formatDate } from "@angular/common";

export class User {
    public isSelected = false;
    constructor(
        public userId: number,
        public firstName: string,
        public lastName: string,
        public email: string,
        public phone: string,
        public picture: string | undefined,
        public authorization: number,
        public isSeller: boolean,
        public disabled?: number
    ){
      //if(picture) this.picture = 'data:image/png;base64,'+this.picture;
    }

    getFullName(): string {
      return this.firstName + ' ' + this.lastName;
    }

    getAvatar(): string {
      if(this.picture != null && this.picture.length > 0) return 'data:image/png;base64,'+this.picture.trim();
      else return './assets/userimageplaceholder.svg';
    }
}
