<div class="view_body">
  <div class="customer_title_wrapper">
    <div class="customer_title_header">
      <h1>{{selectedCustomer.company_name}}</h1>
    </div>
    <app-action-menu *ngIf="!isPhone"
      (editEmitter)="editEmitter.emit(selectedCustomer)"
      (selectionCreateTicket)="selectionOpenCreateTicket()"
      (createProjectEmitter)="createProjectEmitter.emit()"
    ></app-action-menu>
    <!-- <div id="action_menu">
      <img class="action_menu_button_icon" src="../../../assets/navdots.svg">
      <div id="action_menu_box">
        <div class="action" (click)="editEmitter.emit(selectedCustomer)">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.046" height="12.045" viewBox="0 0 12.046 12.045">
            <g class="action_icon" transform="translate(-806 -144.477)">
              <path class="action_menu_icon" id="Pfad_943" data-name="Pfad 943" d="M7.192,124.8.1,131.893a.334.334,0,0,0-.1.236v1.359a.365.365,0,0,0,.365.365H1.723a.334.334,0,0,0,.236-.1l7.094-7.094a.194.194,0,0,0,0-.275L7.467,124.8A.194.194,0,0,0,7.192,124.8Z" transform="translate(806 22.669)" fill="#1166B2"/>
              <path class="action_menu_icon" id="Pfad_944" data-name="Pfad 944" d="M348.757.383a1.317,1.317,0,0,0-1.862,0l-1.619,1.622a.194.194,0,0,0,0,.275l1.587,1.587a.194.194,0,0,0,.275,0l1.619-1.619a1.317,1.317,0,0,0,0-1.862Z" transform="translate(468.903 144.479)" fill="#1166B2"/>
            </g>
          </svg>
          <label>Bearbeiten</label>
        </div>
        <div class="action" (click)="selectionOpenCreateTicket()">
          <svg xmlns="http://www.w3.org/2000/svg" width="6.14" height="6.14" viewBox="0 0 6.14 6.14">
            <g class="action_icon" transform="translate(5.64 0.5) rotate(90)">
              <path id="Linie_2" data-name="Linie 2" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(2.57)" fill="#3b88cc"/>
              <path id="Linie_3" data-name="Linie 3" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(5.14 2.57) rotate(90)" fill="#3b88cc"/>
            </g>
          </svg>
          <label>Ticket erstellen</label>
        </div>
        <div class="action" (click)="createProjectEmitter.emit()">
          <svg xmlns="http://www.w3.org/2000/svg" width="6.14" height="6.14" viewBox="0 0 6.14 6.14">
            <g class="action_icon" transform="translate(5.64 0.5) rotate(90)">
              <path id="Linie_2" data-name="Linie 2" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(2.57)" fill="#3b88cc"/>
              <path id="Linie_3" data-name="Linie 3" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(5.14 2.57) rotate(90)" fill="#3b88cc"/>
            </g>
          </svg>
          <label>Projekt erstellen</label>
        </div>
        <div class="action" *ngIf="false" (click)="null">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.667" height="14" viewBox="0 0 11.667 14">
            <path class="action_icon" d="M53.75,2.333H51.942A2.922,2.922,0,0,0,49.084,0H47.917a2.922,2.922,0,0,0-2.858,2.333H43.25a.583.583,0,0,0,0,1.167h.583v7.583A2.92,2.92,0,0,0,46.75,14h3.5a2.92,2.92,0,0,0,2.917-2.917V3.5h.583a.583.583,0,0,0,0-1.167ZM47.917,9.917a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0v3.5Zm2.333,0a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0ZM46.267,2.333a1.754,1.754,0,0,1,1.65-1.167h1.167a1.754,1.754,0,0,1,1.65,1.167Z" transform="translate(-42.667)" fill="#3b88cc"/>
          </svg>
          <label>Löschen</label>
        </div>
      </div>
    </div> -->
  </div>
  <div class="customer_title_wrapper">
    <label class="lbl_box lbl_box_gray">{{selectedCustomer.customer_typ == 1 ? 'Kunde' : (selectedCustomer.customer_typ == 2 ? 'Interessent' : 'Archiviert')}}</label>
    <label class="lbl_regular_11">betreut von {{selectedCustomer.seller?.firstName}} {{selectedCustomer.seller?.lastName}}</label>
  </div>
  <div class="tab_row">
    <div class="tab_wrapper">
      <button class="btn_tab" [ngClass]="tab == 1 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="openViewInfo()">Informatives</button>
      <button class="btn_tab" [ngClass]="tab == 2 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="openViewTickets()">Tickets<label class="lbl_counter" *ngIf="displayedTicketsCount > 0">{{displayedTicketsCount}}</label></button>
    </div>
    <div>
      <button *ngIf="tab == 2 && !showCreateTicket" class="btn_tab btn_tab_blue" (click)="selectionOpenCreateTicket()">Ticket erstellen</button>
      <button *ngIf="tab == 2 && showCreateTicket" class="btn_tab btn_tab_gray" (click)="selectionCloseThread();">Abbrechen</button>
    </div>
  </div>
  <div class="grid" *ngIf="tab == 1">
    <div class="column">
      <div class="data_box">
        <div class="data_box_package">
          <h2>Kunde</h2>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.company_name}}</label>
          <label class="lbl_customer_data_box_data lbl_light">WMPD {{selectedCustomer.customer_id}}</label>
        </div>
        <div class="data_box_package">
          <h2>Standard Rechnungsadresse</h2>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.company_name}}</label>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.atu_number}}</label>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.address.street}}</label>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.address.postalCode}} {{selectedCustomer.billing_address.address.city}}</label>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.billing_mail}}</label>
        </div>
        <div class="data_box_package" [class.data_box_package_last]="!selectedCustomer.website_url" >
          <h2>Ansprechperson</h2>
          <div class="data_box_inner_flex">
            <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.contact_person?.title.name}}</label>
            <label class="lbl_box lbl_box_gray">{{selectedCustomer.billing_address.contact_person?.isPolite ? 'Sie' : 'Du'}}</label>
          </div>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.contact_person?.isMale ? 'Herr ' : 'Frau '}} {{selectedCustomer.billing_address.contact_person?.firstName}} {{selectedCustomer.billing_address.contact_person?.lastName}}</label>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.contact_person?.phone}}</label>
          <label class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.contact_person?.email}}</label>
          <label *ngIf="selectedCustomer.billing_address.contact_person?.position" class="lbl_customer_data_box_data">{{selectedCustomer.billing_address.contact_person?.position}}</label>
        </div>
        <div *ngIf="selectedCustomer.website_url" class="data_box_package data_box_package_last">
          <h2>Website</h2>
          <label class="lbl_customer_data_box_data">
            {{selectedCustomer.website_url}}
            <img (click)="selectedCustomer.openUrl()" class="img-go-to-web" src="./assets/linksymbol.svg">
          </label>
        </div>
      </div>
      <div class="data_box">
        <div class="data_box_package">
          <h2>Seller-Informationen</h2>
          <div class="flex">
            <label class="lbl_customer_data_box_data">Hat Bewertung</label>
            <img [src]="selectedCustomer.has_rating ? '../../../assets/positive.svg' : '../../../assets/negative.svg'">
          </div>
          <div class="flex">
            <label class="lbl_customer_data_box_data">Datenschutz vorhanden</label>
            <img [src]="selectedCustomer.has_dataprivacy ? '../../../assets/positive.svg' : '../../../assets/negative.svg'">
          </div>
        </div>
        <div class="data_box_package data_box_package_last">
          <div class="flex">
            <label class="lbl_customer_data_box_data">Letzter Termin</label>
            <div class="flex_last_appointment">
              <label class="lbl_customer_data_box_data" style="margin-bottom: 0;">{{selectedCustomer.last_appointments && selectedCustomer.last_appointments.length != 0 ? (selectedCustomer.last_appointments[selectedCustomer.last_appointments.length - 1] | date: 'dd. MMM yyyy') : '---'}}</label>
              <div *ngIf="selectedCustomer.last_appointments && selectedCustomer.last_appointments.length != 0" class="date_traffic_light" [ngClass]="selectedCustomer.getLastAppointmentStatus()"></div>
            </div>
          </div>
          <button *ngIf="selectedCustomer.last_appointments.length > 1" class="button_icon_lbl" style="align-self: flex-start; padding: 0;" (click)="showLastAppointmentsEmitter.emit()">History ansehen</button>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="data_box_array" *ngIf="selectedCustomer.contact_persons.length > 1">
        <h2>Ansprechpersonen</h2>
        <div *ngFor="let contact of selectedCustomer.contact_persons; let last = last">
          <div class="data_box_package" [class.data_box_package_last]="last" *ngIf="contact.contact_id != this.selectedCustomer.billing_address.contact_person.contact_id">
            <div class="data_box_inner_flex">
              <label class="lbl_customer_data_box_data">{{contact.title?.name}}</label>
              <label class="lbl_box lbl_box_gray">{{contact.isPolite ? 'Sie' : 'Du'}}</label>
            </div>
            <label class="lbl_customer_data_box_data">{{contact.isMale ? 'Herr ' : 'Frau '}} {{contact.firstName}} {{contact.lastName}}</label>
            <label class="lbl_customer_data_box_data">{{contact.phone}}</label>
            <label class="lbl_customer_data_box_data">{{contact.email}}</label>
            <label *ngIf="contact.position" class="lbl_customer_data_box_data">{{contact.position}}</label>
          </div>
        </div>
      </div>
      <div class="data_box_array" *ngIf="showSectionsCustomer()">
        <h2 class="h2_single">Zusatzinformationen</h2>
        <div *ngFor="let section of selectedCustomer.sections; let i = index">
          <div *ngIf="showSingleSectionCustomer(section)">
            <div class="data_box_package" [class.data_box_package_last]="setSectionsCustomerBorder(i+1)">
              <h3>{{section.section_name}}</h3>
              <div *ngFor="let row of section.section_rows" style="width: 100%;">
                <div *ngIf="row.data_value">
                  <app-section-overview
                    [sectionRow]="row"
                  ></app-section-overview>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="view_one_projects" class="column">
      <div class="data_box">
        <h2 class="h2_single">Projekte</h2>
        <div *ngIf="selectedCustomer.projects.length == 0" id="empty_threads">
          <h3>Keine laufende Projekte</h3>
        </div>
        <div *ngIf="selectedCustomer.projects.length > 0" id="projects_list">
          <div class="data_box_paragraph_projects" *ngFor="let project of this.selectedCustomer.projects" (click)="selectionProjectEmitter.emit(project)">
            <!-- <label class="lbl-info" *ngIf="true">
              <div class="notify-dot"></div>
              {{"2"}} offene Tickets
            </label> -->
            <div class="data_box_projects_title">
              <label class="lbl_light_11">{{project.awork_project.name}} - PO{{project.project_id}}</label>
              <label *ngIf="project.amountOpenUserTickets" class="lbl_color_box_red lbl_open_threads">{{project.amountOpenUserTickets}}</label>
            </div>
            <label *ngIf="project.awork_project.description" class="lbl_customer_data_box_data" style="width: 90%" [innerHTML]="project.awork_project.description"></label>
            <div class="project_additional_info_container">
              <label class="lbl_box lbl_box_trans">{{project.awork_project.project_typ.typ_name}}</label>
              <label class="lbl_box" [ngClass]="project.awork_project.getStatusColorClass()">{{project.awork_project.status_name}}</label>
              <label class="lbl_box" [ngClass]="project.getCapicityColorClass()">{{project.awork_project.getWorkPercentage() | number: '1.0-0'}}% verbraucht</label>
              <label *ngIf="project.status == 2" class="lbl_box lbl_box_red">Archiviert</label>
            </div>
            <img class="icon_project_nav" src="./assets/arrowmenucolor.svg">
          </div>
        </div>
        <div class="data_box_package data_box_package_last">
          <button class="button_icon_lbl" (click)="createProjectEmitter.emit()"><img class="btn_label_icon" src="../../../assets/addelement.svg">Neues Projekt erstellen</button>
        </div>
      </div>
    </div>
  </div>
  <div class="customer_ticket_body" *ngIf="tab == 2">
    <app-tickets-new
      [users]="users"
      [customer]="selectedCustomer"
      [isCreate]="showCreateTicket"
      (showCreateEmitter)="showCreateTicket = $event"
      (ticketsCountEmitter)="countDisplayedTickets($event)"
    ></app-tickets-new>
    <!-- <app-customer-tickets
      [showTab]="tab"
      [showCreateTicket]="showCreateTicket"
      [currentUser]="currentUser"
      [customer]="selectedCustomer"
      [customersThreadsFiltered]="threadsFiltered"
      [selectedThread]="selectedThread"
      [users]="users"
      [minimizeSidebar]="minimizeSidebar"
      (successResolveTicketEmitter)="successResolveTicket($event)"
      (successRestoreTicketEmitter)="successRestoreTicket($event)"
      (successCreateTicketEmitter)="successCreateTicket($event)"
      (successCreateMessageEmitter)="successCreateMessageEmitter($event)"
      (selectionThreadEmitter)="selectionThread($event)"
      (selectionCloseThreadEmitter)="selectionCloseThread()"
      (searchTicketsEmitter)="searchTicketsAction($event)"
    ></app-customer-tickets> -->
  </div>
</div>
