<div class="dialog_view">
    <div class="dialog_window">
        <button class="dialog_window_btn_close" (click)="close()"><img src="./assets/negativewhite.svg"></button>
        <div>
            <div class="dialog_window_header">
                <h2>Ticket verschieben</h2>
            </div>
            <div class="dialog_window_body">
                <form [formGroup]="ticketForm" *ngIf="ticketForm">
                  <label id="customer_lbl" for="customer">Kunde wählen</label>
                    <select id="customer" formControlName="customer_id">
                    <option *ngFor="let customer of customers" [value]="customer.customer_id">
                        {{customer.company_name}}
                    </option>
                    </select>
                  <label id="project_lbl" for="project">Projekt wählen</label>
                  <select id="project" formControlName="project_id">
                    <option [value]="null">Kein Projekt</option>
                    <option *ngFor="let project of projects" [value]="project.project_id">
                      {{ project.awork_project.name }}
                    </option>
                  </select>
                </form>
              </div>
        </div>
        <button class="dialog_window_btn_submit" (click)="transferTicket()">Verschieben</button>
    </div>
</div>
