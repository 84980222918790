<div id="menu_wrapper" *ngIf="!isPhone">
  <img id="menu_logo" src="../../../assets/waymarklogo.svg" (click)="reload('/customers')">
  <div #menu_dropdown id="menu_dropdown">
    <div (click)="showDropdown = !showDropdown" class="selected_wrapper">
      <label class="lbl_header_menu">{{getTitle()}}</label>
      <img src="../../../assets/arrowdropdown.svg" style="width: 10px;">
    </div>
    <div id="menu_dropdown_body" *ngIf="showDropdown">
      <div class="dropdown_value lbl_header_menu" (click)="reload('/customers')">Kunden</div>
      <div class="dropdown_value lbl_header_menu" (click)="reload('/tickets')">Tickets</div>
    </div>
  </div>
  <div id="menu_action_container">
    <div class="menu_action_icon" (click)="reload('/customers/create')">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.999 16">
        <g transform="translate(1 1)">
          <path class="icon" data-name="Vereinigungsmenge 47" d="M-57.712-1431v-6h-6a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6v-6a1,1,0,0,1,1-1,1,1,0,0,1,1,1v6h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-6v6a1,1,0,0,1-1,1A1,1,0,0,1-57.712-1431Z" transform="translate(63.711 1445)" fill="#ff2e2e"/>
        </g>
      </svg>
    </div>
    <div class="menu_action_icon" (click)="selectionSearch()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.639" height="17.234" viewBox="0 0 16.639 17.234">
        <path class="icon" id="searchwhite" d="M16.386,15.7l-4.1-4.266a6.955,6.955,0,1,0-5.326,2.485,6.884,6.884,0,0,0,3.986-1.259l4.133,4.3A.908.908,0,1,0,16.386,15.7M6.958,1.815A5.143,5.143,0,1,1,1.815,6.958,5.149,5.149,0,0,1,6.958,1.815" fill="#ff2e2e"/>
      </svg>
    </div>
    <!--<div id="btn_open_notify" class="menu_action_icon" (click)="notifyThreads.length > 0 ? handleTicketBox(true) : null">
      <div *ngIf="notifyThreads.length > 0" id="notify_counter">{{notifyThreads.length}}</div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.297 21.612" style="transform: translate(0,-2px)">
        <g id="notification_icon" transform="translate(-1386.623 -1160.25)">
          <path class="icon" id="Rechteck_819" data-name="Rechteck 819" d="M6.686-.75a7.444,7.444,0,0,1,7.436,7.436v8.673a.75.75,0,0,1-.75.75H0a.75.75,0,0,1-.75-.75V6.686A7.444,7.444,0,0,1,6.686-.75Zm5.936,15.358V6.686A5.942,5.942,0,0,0,6.686.75h0A5.942,5.942,0,0,0,.75,6.686v7.923Z" transform="translate(1388.586 1163.069)" fill="#ff2e2e"/>
          <path class="icon" id="Linie_339" data-name="Linie 339" d="M15.8.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H15.8a.75.75,0,0,1,.75.75A.75.75,0,0,1,15.8.75Z" transform="translate(1387.373 1178.427)" fill="#ff2e2e"/>
          <g id="Gruppe_1177" data-name="Gruppe 1177">
            <path class="icon" id="Pfad_866" data-name="Pfad 866" d="M1394.2,1180.075h2.148a.5.5,0,0,1,.5.5,1.606,1.606,0,0,1-3.148,0A.5.5,0,0,1,1394.2,1180.075Z" fill="#ff2e2e"/>
          </g>
          <path class="icon" id="Linie_340" data-name="Linie 340" d="M0,2.819a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V2.069A.75.75,0,0,1,0,2.819Z" transform="translate(1395.272 1161)" fill="#ff2e2e"/>
        </g>
      </svg>
    </div>-->
    <div class="user_img_container" (click)="logoutAction()">
      <img *ngIf="registeredUser" [ngClass]="registeredUser.picture ? 'user_img' : 'user_img_placeholder'" [src]="registeredUser.getAvatar()">
      <div id="logout_icon"></div>
    </div>
  </div>
</div>

<app-dialog-open-notifications *ngIf="showTicketBox"
    [tickets]="notifyThreads"
    [show]="showTicketBox"
    (openTicketEmitter)="openTicket($event)"
    (closeDialogEmitter)="handleTicketBox($event)"
></app-dialog-open-notifications>
<app-dialog-search *ngIf="showSearchDialog"
   (closeEmitter)="closeSearchDialog()"
></app-dialog-search>

<div id="menu_wrapper" *ngIf="isPhone">
  <div id="btn_menu" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event" (click)="toggleMenu()" [class.open]="isMobileMenuOpen">
    <div class="btn_menu_line"></div>
    <div class="btn_menu_line"></div>
    <div class="btn_menu_line"></div>
  </div>
  <div #menu id="menu_container" *ngIf="showDropdown">

    <div id="menu_body">
      <button class="btn_menu" [ngClass]="selectedMenu == 1 ? 'btn_menu_active' : ''" (click)="reload('/customers')">Kunden</button>
      <!-- <button class="btn_menu" [ngClass]="selectedMenu == 2 ? 'btn_menu_active' : ''" (click)="reload('/tickets')">Tickets</button> -->
    </div>
    <div id="menu_footer">
      <img id="menu_logo" src="../../../assets/waymarklogo.svg">
      <label class="lbl_light">{{version}}</label>
    </div>
  </div>
  <div id="menu_action_container">
    <div class="menu_action_icon" (click)="reload('/customers/create')" *ngIf="!isPhone">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.999 16">
        <g transform="translate(1 1)">
          <path class="icon" data-name="Vereinigungsmenge 47" d="M-57.712-1431v-6h-6a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6v-6a1,1,0,0,1,1-1,1,1,0,0,1,1,1v6h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-6v6a1,1,0,0,1-1,1A1,1,0,0,1-57.712-1431Z" transform="translate(63.711 1445)" fill="#ff2e2e"/>
        </g>
      </svg>
    </div>
    <div class="menu_action_icon" (click)="notifyThreads.length > 0 ? openNotifyDialog() : null" *ngIf="!isPhone">
      <div *ngIf="notifyThreads.length > 0" id="notify_counter">{{notifyThreads.length}}</div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.297 21.612" style="transform: translate(0,-2px)">
        <g id="notification_icon" transform="translate(-1386.623 -1160.25)">
          <path class="icon" id="Rechteck_819" data-name="Rechteck 819" d="M6.686-.75a7.444,7.444,0,0,1,7.436,7.436v8.673a.75.75,0,0,1-.75.75H0a.75.75,0,0,1-.75-.75V6.686A7.444,7.444,0,0,1,6.686-.75Zm5.936,15.358V6.686A5.942,5.942,0,0,0,6.686.75h0A5.942,5.942,0,0,0,.75,6.686v7.923Z" transform="translate(1388.586 1163.069)" fill="#ff2e2e"/>
          <path class="icon" id="Linie_339" data-name="Linie 339" d="M15.8.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H15.8a.75.75,0,0,1,.75.75A.75.75,0,0,1,15.8.75Z" transform="translate(1387.373 1178.427)" fill="#ff2e2e"/>
          <g id="Gruppe_1177" data-name="Gruppe 1177">
            <path class="icon" id="Pfad_866" data-name="Pfad 866" d="M1394.2,1180.075h2.148a.5.5,0,0,1,.5.5,1.606,1.606,0,0,1-3.148,0A.5.5,0,0,1,1394.2,1180.075Z" fill="#ff2e2e"/>
          </g>
          <path class="icon" id="Linie_340" data-name="Linie 340" d="M0,2.819a.75.75,0,0,1-.75-.75V0A.75.75,0,0,1,0-.75.75.75,0,0,1,.75,0V2.069A.75.75,0,0,1,0,2.819Z" transform="translate(1395.272 1161)" fill="#ff2e2e"/>
        </g>
      </svg>
    </div>
    <div class="user_img_container" (click)="logoutAction()">
      <img *ngIf="registeredUser" [ngClass]="registeredUser.picture ? 'user_img' : 'user_img_placeholder'" [src]="registeredUser.getAvatar()">
      <div id="logout_icon"></div>
    </div>
  </div>
</div>

