import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Project } from 'src/app/buisness-object/project/Project';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';
import { BaseComponent } from '../../BaseComponent';
import { Message } from 'src/app/buisness-object/thread/Message';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { BehaviorSubject, catchError, filter, firstValueFrom, map, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TicketForm } from 'src/app/buisness-object/thread/form/TicketForm';
import { ErrorHelper } from 'src/app/utils/ErrorHelper';
import { MessageService } from 'src/app/service/message/message.service';

@Component({
  selector: 'app-tickets-new',
  templateUrl: './tickets-new.component.html',
  styleUrls: ['./tickets-new.component.scss']
})
export class TicketsNewComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() users: User[] = [];
  @Input() customer: Customer;
  @Input() project: Project;
  @Input() preSelectedTicketId: number;
  @Input() isCreate: boolean = false;
  @Output() showCreateEmitter = new EventEmitter<boolean>();
  @Output() resolveEmitter = new EventEmitter<Thread>();
  @Output() applyFilterEmitter = new EventEmitter<any>();
  @Output() ticketsCountEmitter = new EventEmitter<number>();
  @Output() updateProjectTicketsEmitter = new EventEmitter<Thread[]>();

  public tickets: Thread[] = [];
  public ticketsFiltered: Thread[] = [];
  public openNotificationTickets: Thread[] = [];
  // public selectedTicket: Thread;
  public selectedTicket$ = new BehaviorSubject<any>(null);
  public selectedTicketMessages: Message[] = [];
  public openTicketsCount;
  public currentUser;
  public infoActive = false;
  public isTicketlistActive = true;
  public defaultFilter: any;
  public filterValue: any;
  searchTimeout: any;
  dialogQuerySubsription: Subscription;
  public ticketForm: FormGroup;
  public submitted = false;
  public searchValueTemp;
  public showFilterTickets = false;
  public selectTypes = [
    { label: 'Todo', value: 1 },
    { label: 'Feature', value: 2 },
    { label: 'Bug', value: 3 },
    { label: 'Frage', value: 4 },
    { label: 'Doku', value: 5 }
  ];
  public selectedType: number = this.selectTypes[0].value;
  public activeFilterCount: number;
  public showMoveTicket = false;
  public showEditTicket = false;
  public showImageOpen = false;
  public selectedImageUrl: string = '';
  public droppedImageBase64: string | null = null;
  public ongoingMessagesDataRequest = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private ticketService: ThreadService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private fromBuilder: FormBuilder,
    private cdk: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.droppedImageBase64 = '';
    this.defaultFilter = this.ticketService.getDefaultFilter();
    this.filterValue = CloneObject.deepCopy(this.defaultFilter);
    this.prepare();
    this.selectedTicket$.subscribe((ticket) => {
      if(ticket && !this.ongoingMessagesDataRequest){
        console.log(ticket)
        this.getMessageImages();
      }
    })
  }

  async prepare() {
    if(this.project != null) await this.prepareTicketsProject();
    else await this.prepareTicketsCustomer();
    this.getNotifications();
    this.setDialogSubscription();
    this.ticketForm = TicketForm.getTicketForm(this.fromBuilder);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let listMessages = document.getElementById('ticket-details-body');
      if(listMessages){
        listMessages.scrollTop = listMessages.scrollHeight;
      }
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['isCreate']){
      if(this.searchValueTemp){
        setTimeout(() => {
          let inputSearch = document.getElementById('input-search-tickets') as HTMLInputElement;
          if(inputSearch) inputSearch.value = this.searchValueTemp;
        }, 50);
      }
    }
  }

  ngOnDestroy(): void {
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getMessageIcon(message: Message): string {
    switch (message.attachment_type) {
      case 'image': return './assets/document-transaction.svg';  break;
      default: return './assets/tickedaddedcomment.svg'; break;
    }
  }

  // async requestMessageImages(ticket): Promise<void> {
  //   for(let oldMessage of ticket.messages) {
  //     if(oldMessage.attachment_type == "image" && oldMessage.attachment == undefined) {
  //       let message = await firstValueFrom(this.messageService.getMessage(ticket.threadId, oldMessage.messageId));
  //       oldMessage.attachment = message.attachment;
  //       if(this.project != null) {
  //         this.tickets = [];
  //         this.prepareTicketsProject();
  //       } else {
  //         this.tickets = [];
  //         this.prepareTicketsCustomer();
  //       }
  //       this.applyFilter();
  //       if(this.selectedTicket$.getValue() != null) {
  //         this.selectedTicket$.next(this.ticketsFiltered.find((t) => t.threadId == this.selectedTicket$.getValue().threadId));
  //       }
  //     }
  //   }
  // }

  async getMessageImages() {
    this.ongoingMessagesDataRequest = true;
    let ticket = this.selectedTicket$.getValue();
    let promises: Promise<any>[] = [];
    if(ticket.messages) {
      for(let msg of ticket.messages){
        msg.icon = this.getMessageIcon(msg);
        if(msg.attachment_type == "image" && msg.attachment == undefined) {
          promises.push(firstValueFrom(this.getMessageImage(ticket.threadId, msg)));
        }
      }
    }
    await Promise.all(promises).then((results) => {
      results.forEach((updatedMessage) => {
        let originalMessage = ticket.messages.find((msg) => msg.messageId === updatedMessage.messageId);
        if(originalMessage) {
          originalMessage.attachment = updatedMessage.attachment;
        }
      });
      this.selectedTicketMessages = ticket.messages;
      this.selectedTicket$.next(ticket);
      this.ongoingMessagesDataRequest = false;
    });
  }

  getMessageImage(ticketId: number, msg: Message): Observable<Message> {
    return this.messageService.getMessage(ticketId, msg.messageId).pipe(
      map(result => {
        return result;
      }),
      catchError(() => of(null))
    )
  }

  async prepareTicketsCustomer() {
    this.openTicketsCount = 0;
    let promises: Promise<any>[] = [];
    if(this.customer.threads && this.customer.threads.length > 0){
      for(let ticket of this.customer!.threads){
        if(!ticket.resolved) this.openTicketsCount++;
        for(let user of this.users){
          if(ticket.userId == user.userId) ticket.user = user;
          if(ticket.resolved_by_user_id == user.userId) ticket.resolved_by_user = user;
          if(ticket.messages) {
            for(let msg of ticket.messages){
              if(msg.userId == user.userId){
                msg.user = user;
              }
            }
          }
          if(this.customer.seller_id == user.userId) this.customer.seller = user;
          if(user.userId == Number(localStorage.getItem('user_id'))) this.currentUser = user;
        }
        // promises.push(this.requestMessageImages(ticket));
      }
      for(let t of this.customer.threads){
        t.notifyOpen = false;
        this.tickets.push(CloneObject.deepCopy(t));
      }
      await Promise.all(promises);
    }
  }

  async prepareTicketsProject() {
    this.openTicketsCount = 0;
    let promises: Promise<any>[] = [];
    if(this.project.threads && this.project.threads.length > 0){
      for(let ticket of this.project!.threads){
        ticket.project_id = this.project.project_id;
        if(!ticket.resolved) this.openTicketsCount++;
        for(let user of this.users){
          if(ticket.userId == user.userId) ticket.user = user;
          if(ticket.resolved_by_user_id == user.userId) ticket.resolved_by_user = user;
          for(let msg of ticket.messages){
            if(msg.userId == user.userId){
              msg.user = user;
            }
          }
          if(this.customer.seller_id == user.userId) this.customer.seller = user;
          if(user.userId == Number(localStorage.getItem('user_id'))) this.currentUser = user;
        }
        //promises.push(this.requestMessageImages(ticket));
      }
      for(let t of this.project.threads){
        t.notifyOpen = false;
        this.tickets.push(CloneObject.deepCopy(t));
      }
      await Promise.all(promises);
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'restore_thread') this.restoreTicket(value.submit_value);
      }
    });
  }

  openTicketOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      if(params && params['ticketId']){
        const id = params['ticketId'];
        let index = this.tickets.findIndex((thread) => thread.threadId == id);
        if(index > -1){
          this.selectionTicket(this.tickets[index]);
        }
      }
    });
  }

  sortTickets() {
    this.ticketsFiltered.sort((a, b) => {
      if(a.resolved && b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      else if(a.resolved && !b.resolved) return 1;
      else if(!a.resolved && b.resolved) return -1;
      else if(!a.resolved && !b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      return 0;
    })
  }

  closeFilterDialogAndFilter(newFilter: any) {
    this.showFilterTickets = false;
    this.filterValue = newFilter;
    this.applyFilter();
  }

  updateCloseMoveDialog(updatedTickets: Thread[]) {
    if(this.project != null) {
      this.project.threads = updatedTickets;
      this.tickets = [];
      this.selectedTicket$.next(null);
      this.prepareTicketsProject();
      this.updateProjectTicketsEmitter.emit(this.project.threads);
    } else {
      this.customer.threads = updatedTickets;
      this.tickets = [];
      this.selectedTicket$.next(null);
      this.prepareTicketsCustomer();
    }
    this.applyFilter();
    this.cdk.detectChanges();
    this.showMoveTicket = false;
  }

  closeMoveDialog() {
    this.showMoveTicket = false;
  }

  closeEditDialog() {
    this.showEditTicket = false;
  }

  closeImageDialog() {
    this.showImageOpen = false;
  }

  openImage(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    this.showImageOpen = true;
  }

  applyFilter(searchValue?: string|undefined, timeout?: number | undefined) {
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.ticketsFiltered = this.tickets; //.filter((t) => this.filter.open ? t.resolved == null : t.resolved != null);
      if(searchValue == undefined){
        let searchInputTickets = document.getElementById('input-search-tickets') as HTMLInputElement;
        if(searchInputTickets != null) searchValue = searchInputTickets.value;
      }
      if(searchValue != undefined && searchValue.length > 0){
        this.searchValueTemp = searchValue;
        this.ticketsFiltered = this.searchTickets(this.ticketsFiltered, searchValue);
      }
      this.activeFilterCount = 0;
      this.ticketsFiltered = this.ticketsFiltered.filter((ticket) => {
        const matchesTicketType = this.filterValue.ticket_typ.includes(ticket.type);
        const matchesResolvedStatus = this.filterValue.status == 0 ? true : this.filterValue.status == ticket.status;
        const matchesSubscription = this.filterValue.only_subscribed ? ticket.subscribe : true;
        const matchesDokuTicketAndSubscription = ticket.type == 5 ? ticket.subscribe : true;
        return matchesTicketType && matchesResolvedStatus && matchesSubscription && matchesDokuTicketAndSubscription;
      });
      if (this.filterValue.ticket_typ.length != 5 && this.filterValue.ticket_typ.length != 6) this.activeFilterCount++;
      if (this.filterValue.status != 0) this.activeFilterCount++;
      if (this.filterValue.only_subscribed) this.activeFilterCount++;
      this.ticketsCountEmitter.emit(this.ticketsFiltered.length);
      this.sortTickets();
      //if(this.ticketsFiltered.length > 0) this.selectedTicket = this.ticketsFiltered[0];
      //else this.selectedTicket = null;
    }, timeout == undefined ? 0 : timeout);
  }

  resetFilter() {
    let searchInputTickets = document.getElementById('input-search-tickets') as HTMLInputElement;
    if(searchInputTickets != null) searchInputTickets.value = '';
    this.applyFilter();
  }

  searchTickets(toSearchValues: Thread[], value: string): any[] {
    let items = toSearchValues.filter((t) => {
      return t.user.firstName.toLowerCase().includes(value.toLowerCase()) ||
           t.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
           (t.user.firstName + ' ' + t.user.lastName).toLowerCase().includes(value.toLowerCase()) ||
           t.threadId.toString().toLowerCase().includes(value.toLowerCase()) ||
           this.messagesIncludeSearch(value, t.messages) ||
           t.threadId == this.selectedTicket$.getValue()?.threadId ||
           t.subject.toLowerCase().includes(value.toLowerCase());
    });
    return items;
  }

  messagesIncludeSearch(value: string, messages: Message[]): boolean {
    for(let msg of messages){
      if(msg.message.toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  }

  selectionSubscription() {
    if(!this.selectedTicket$.getValue()?.subscribe){
      this.ticketService.subscribe(this.selectedTicket$.getValue()?.threadId).subscribe(() => {
        let temp = this.selectedTicket$.getValue();
        temp.subscribe = true;
        this.selectedTicket$.next(temp);
      })
    } else {
      this.ticketService.unsubscribe(this.selectedTicket$.getValue()?.threadId).subscribe(() => {
        let temp = this.selectedTicket$.getValue();
        temp.subscribe = false;
        this.selectedTicket$.next(temp);
      })
    }
  }

  getNotifications() {
    this.ticketService.getNotifications().subscribe((result) => {
      if(result){
        this.openNotificationTickets = result;
        for(let thread of this.openNotificationTickets){
          let index = this.tickets.findIndex(t => t.threadId == thread.threadId);
          if(index > -1) this.tickets[index].notifyOpen = true;
        }
        this.applyFilter();
        this.openTicketOnInit();
      }
    });
  }

  selectionTicket(ticket: Thread) {
    if(ticket.notifyOpen){
      ticket.notifyOpen = false;
      this.ticketService.updateThread(ticket).subscribe((t) => {
        if(t){
          let index1 = this.tickets.findIndex(t => t.threadId == ticket.threadId);
          if(index1 > -1) this.tickets[index1].notifyOpen = false;
          let index2 = this.ticketsFiltered.findIndex(t => t.threadId == ticket.threadId);
          if(index2 > -1) this.ticketsFiltered[index2].notifyOpen = false;
        }
      })
    }
    this.ticketForm = TicketForm.getTicketForm(this.fromBuilder);
    const content = document.getElementById('text-input-1');
    if(content) content.innerText = '';
    this.selectedTicket$.next(ticket);
    let makeRelative = false;
    if(this.selectedTicket$.getValue().getThreadTitle().length > 500){
      makeRelative = true;
    }
    setTimeout(() => {
      const ticketHeader = document.getElementById('ticket-details-header');
      if(ticketHeader){
        if(ticketHeader.offsetHeight > 280) ticketHeader.setAttribute('style', this.getStyleNotSticky());
        else ticketHeader.setAttribute('style', this.getStyleSticky());
      }
    }, 200);
  }

  getStyleSticky(): string {
    return 'position: sticky; ' +
      'top: 0; ' +
      'left: 0; ' +
      'background-color: #ffffff; ' +
      'padding: 10px 12px; ' +
      'z-index: 100;';
  }

  getStyleNotSticky(): string {
    return 'position: relative; ' +
      'top: 0; ' +
      'left: 0; ' +
      'background-color: #ffffff; ' +
      'padding: 10px 12px; ' +
      'z-index: 100;';
  }

  toggleTicketlist(): void {
    if (this.isPhone) {
      this.isTicketlistActive = !this.isTicketlistActive;
      document.getElementById("ticket-list").style.display = this.isTicketlistActive ? 'block' : 'none';
      document.getElementById("ticket-details").style.display = this.isTicketlistActive ? 'none' : 'block';
    }
  }

  resolve(ticket: Thread) {
    let temp = CloneObject.deepCopy(ticket);
    temp.status = 2;
    temp.resolved = new Date().getTime();
    temp.resolved_by_user_id = this.currentUser.userId;
    temp.resolved_by_user = this.currentUser;
    this.ticketService.updateThread(temp).subscribe((thread) => {
      if(thread){
        this.openTicketsCount--;
        this.selectionTicket(temp);
        this.updateTicketLocal(temp);
        // setTimeout(() => {
        //   this.selectionTicket(temp);
        //   let listItem = document.getElementById('ticket-item_'+temp.threadId);
        //   if(listItem){
        //     listItem.scrollIntoView({ behavior: 'smooth' });
        //   }
        // }, 150);
        this.resolveEmitter.emit(temp)
        this.dialogService.showNotification({
          title: 'Ticket aufgelöst',
          message: this.dialogService.getTitle(),
          success: true
        });
      }
    });
  }

  selectionRestore(thread: Thread) {
    this.dialogService.openQuery(
      {
        title: 'Ticket wiederherstellen',
        message: 'Ohje... Doch nicht alles erledigt?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Wiederherstellen',
        typ: 'restore_thread',
        submit_value: thread,
        showClose: true,
        is_choise: true
      }
    );
  }

  restoreTicket(ticket: Thread) {
    // ticket.status = 1;
    // ticket.resolved = null;
    // ticket.resolved_by_user_id = 0;
    // ticket.resolved_by_user = null;
    let temp = CloneObject.deepCopy(ticket);
    temp.status = 1;
    temp.resolved = null;
    temp.resolved_by_user_id = 0;
    temp.resolved_by_user = null;
    this.ticketService.updateThread(temp).subscribe((thread) => {
      if(thread){
        this.openTicketsCount++;
        this.updateTicketLocal(temp);
        this.selectionTicket(temp);
        this.dialogService.showNotification({
          title: "Ticket wiederhergestellt",
          message: this.dialogService.getPfui(),
          success: true
        });
      }
    });
  }

  updateTicketLocal(ticket: Thread) {
    let index1 = this.tickets.findIndex(t => t.threadId == ticket.threadId);
    if(index1 > -1) this.tickets[index1] = ticket
    let index2 = this.ticketsFiltered.findIndex(t => t.threadId == ticket.threadId);
    if(index2 > -1) this.ticketsFiltered[index2] = ticket;
    this.applyFilter();
    // this.cdk.detectChanges();
  }

  public onSelectType(type: { label: string, value: number }): void {
    this.selectedType = type.value;
    this.ticketForm.get('thread_typ')?.setValue(type.value);
  }

  handleImageDropped(base64Image: string) {
    this.droppedImageBase64 = base64Image;
  }

  createTicket() {
    const attachmentType = this.droppedImageBase64 ? 'image' : 'text';
    const attachment = this.droppedImageBase64 ? this.droppedImageBase64 : null;
    let messageContent = this.ticketForm.controls['message'].value;
    if(attachment) {
      messageContent = '';
    } 

    this.submitted = true
    if(this.ticketForm.get('subject').invalid || this.ticketForm.get('thread_typ').invalid){
      ErrorHelper.showFormError(this.ticketForm);
      return;
    }
    let thread = new Thread(
      0,
      this.customer.customer_id,
      Number(localStorage.getItem('user_id')),
      this.ticketForm.controls['thread_typ'].value,
      1,
      false,
      false, //notification
      Date.now(),
      0,
      0,
    );

    thread.subject = this.ticketForm.get('subject').value;
    thread.project_id = this.project ? this.project.project_id : null;
    thread.messages.push(new Message(
      0,
      0,
      thread.userId,
      messageContent,
      Date.now(),
      attachmentType,
      attachment
    ));

    console.log(thread.messages);

    this.ticketService.createThread(thread).subscribe((newTicket) => {
      if(newTicket){
        console.log(newTicket);
        newTicket.customer = thread.customer;
        for(let user of this.users){
          if(newTicket.userId == user.userId){
            newTicket.user = user;
            if(newTicket.messages?.length > 0){
              newTicket.messages[0].user = user;
            }
            break;
          }
        }

        this.submitted = false;
        this.tickets.push(newTicket);
        this.applyFilter();
        this.selectedType = this.selectTypes[0].value;
        this.ongoingMessagesDataRequest = false;
        this.droppedImageBase64 = null;
        this.showCreateEmitter.emit(false);
        this.selectedTicket$.next(newTicket);
      }
    });
  }

  selectionCreateAnswer(closeTicket: boolean) {
    const attachmentType = this.droppedImageBase64 ? 'image' : 'text';
    const attachment = this.droppedImageBase64 ? this.droppedImageBase64 : null;
    let messageContent = this.ticketForm.controls['message'].value;
    if(attachment) {
      messageContent = '';
    }
    let message = new Message(0,this.selectedTicket$.getValue().threadId,Number(localStorage.getItem('user_id')),messageContent, new Date().getTime(),attachmentType,attachment);
    message.user = this.currentUser;
    this.messageService.createMessage(message).subscribe((thread) => {
      if(thread){
        for(let msg of thread.messages){
          msg.threadId = this.selectedTicket$.getValue().threadId;
          for(let user of this.users){
            if(msg.userId == user.userId) msg.user = user;
          }
        }
        this.selectedTicket$.getValue().messages = thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        if(this.project != null) {
          let projectTicket = this.project.threads.find((t) => t.threadId == this.selectedTicket$.getValue().threadId);
          if(projectTicket){
            projectTicket.messages = this.selectedTicket$.getValue().messages;
          }
        } else {
          let customerTicket = this.customer.threads.find((t) => t.threadId == this.selectedTicket$.getValue().threadId);
          if(customerTicket){
            customerTicket.messages = this.selectedTicket$.getValue().messages;
          }
        }
        //this.requestMessageImages(this.selectedTicket$.getValue());
        this.getMessageImages();
        this.selectedTicket$.getValue().subscribe = true;
        const content = document.getElementById('text-input-1');
        if(content) content.innerText = '';
        this.ticketForm = TicketForm.getTicketForm(this.fromBuilder);
        this.droppedImageBase64 = null;
        setTimeout(() => {
          let listMessages = document.getElementById('ticket-details-body');
          if(listMessages){
            listMessages.scrollTop = listMessages.scrollHeight;
          }
        }, 200);
        if(closeTicket){
          this.resolve(this.selectedTicket$.getValue());
        }
        this.cdk.detectChanges();
      }
    });
  }

  showEdit() {
    this.showEditTicket = true;
  }
}
