import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { Project } from 'src/app/buisness-object/project/Project';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { ProjectFactory } from 'src/app/buisness-object/project/factory/ProjectFactory';
import { ProjectTypeFactory } from 'src/app/buisness-object/project/factory/ProjectTypeFactory';
import { ProjectType } from 'src/app/buisness-object/project/ProjectType';
import { ProjectTag } from 'src/app/buisness-object/project/ProjectTag';
import { ProjectTagFactory } from 'src/app/buisness-object/project/factory/ProjectTagFactory';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  public projects$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
    ) { }

    getProjects(customer_id: number): Observable<Project[]> {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization : 'JWT ' + localStorage.getItem('token')
      });
      let start = new Date();
      console.log(`Request start project...`);
      const observable = this.http.get(environment.api+'/back/project/get/by/customer/id/'+customer_id,{ headers });
      return observable.pipe(
        map((raw: any) => {
          console.log(`Request time project: ${(new Date().getTime()-start.getTime())/1000}`);
          const projects = ProjectFactory.jsonFactory(raw.project);
          projects.sort((a,b) => {
            if(a.creation_date < b.creation_date) return 1;
            if(a.creation_date > b.creation_date) return -1;
            else return 0;
          });
          return projects;
        }),
        catchError(error => {
          if(error.status == 403){
            this.lService.logout();
          } else {
            this.dService.handleError(error);
          }
          return [];
        })
      );
  }

  getAllProjects(reload?: boolean): Observable<Project[]> {
    if(!reload && this.projects$.getValue()){
      return of(this.projects$.getValue());
    } else {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization : 'JWT ' + localStorage.getItem('token')
      });
      const observable = this.http.get(environment.api+'/back/all/projects',{ headers });
      return observable.pipe(
        map((raw: any) => {
          const projects = ProjectFactory.jsonFactory(raw.projects);
          this.projects$.next(projects);
          projects.sort((a,b) => {
            if(a.creation_date < b.creation_date) return 1;
            if(a.creation_date > b.creation_date) return -1;
            else return 0;
          });
          return projects;
        }),
        catchError(error => {
          if(error.status == 403){
            this.lService.logout();
          } else {
            this.dService.handleError(error);
          }
          return [];
        })
      );
    }
}

  createProject(project: Project): Observable<Project> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = project.getJSON();
    const observable = this.http.post(environment.api + '/back/project/create', body, {headers});
    return observable.pipe(
      map((raw: any) => {
        const obj = ProjectFactory.jsonFactoryOne(raw.project);
        obj.awork_project.project_typ.typ_name = project.awork_project.project_typ.typ_name;
        obj.awork_project.status = 'not-started';
        obj.awork_project.status_name = 'Noch nicht gestartet';
        obj.awork_project.budget = 0;
        obj.awork_project.hours_used = 0;
        for(let tag of body.tags){
          for(let tagP of obj.awork_project.tags)
          if(tag.name == tagP.name) tagP.colour = tag.colour
        }
        this.dService.showNotification({
          title: 'Projekt angelegt',
          message: 'Es wurde erfolgreich ein neues Projekt angelegt.',
          success: true
        });
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  updateProject(project: Project): Observable<Project> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = project.getJSONUpdate();
    const observable = this.http.post(environment.api + '/back/project/update', body, {headers});
    return observable.pipe(
      map((raw: any) => {
        const obj = ProjectFactory.jsonFactoryOne(raw.project);
        obj.awork_project.project_typ.typ_name = project.awork_project.project_typ.typ_name;
        this.dService.showNotification({
          title: 'Projekt aktualisiert',
          message: 'Das Projekt wurde erfolgreich aktualisiert.',
          success: true
        });
        return obj;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  deleteProject(project_id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.post(environment.api + '/back/delete/project/' + project_id, undefined, {headers});
    return observable.pipe(
      map((rawCustomer: any) => {
        this.dService.showNotification({
          title: 'Projekt gelöscht',
          message: 'Das Projekt wurde erfolgreich gelöscht.',
          success: true
        });
        return true;
      }),catchError(error => {
        console.log(error)
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getProjectTyps(): Observable<ProjectType[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api +  '/back/project/types', { headers });
    return observable.pipe(
      map((raw: any) => {
        const data = ProjectTypeFactory.jsonFactory(raw);
        return data;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getProjectTags(): Observable<ProjectTag[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api +  '/back/project/tags', { headers });
    return observable.pipe(
      map((raw: any) => {
        const data = ProjectTagFactory.jsonFactory(raw);
        return data;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }
}
