import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { MessageService } from 'src/app/service/message/message.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-customer-ticket-details',
  templateUrl: './customer-ticket-details.component.html',
  styleUrls: ['./customer-ticket-details.component.scss']
})
export class CustomerTicketDetailsComponent implements OnInit {
  @Input() thread: Thread;
  @Input() users: User[];
  @Input() currentUser: User;
  @Input() isProject: boolean;
  @Input() customer: Customer;
  @Input() customers!: Customer[];
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() selectionResolveEmitter = new EventEmitter<Thread>();
  @Output() selectionCloseThreadEmitter = new EventEmitter<undefined>();
  @Output() dissolveEmitter = new EventEmitter<Thread>();
  @Output() successCreateMessageEmitter = new EventEmitter<Thread>();
  @Output() successCreateMessageAndCloseEmitter = new EventEmitter<Thread>();
  @Output() selectionRestoreEmitter = new EventEmitter<Thread>();
  @Output() selectionUnsubscribeEmitter = new EventEmitter<Thread>();

  public answers: Message[] = [];
  public form!: FormGroup;
  public customersFiltered: Customer[] =  [];
  public routeParams: any;

  @ViewChild('inputCustomer') inputCustomer! : ElementRef;
  @ViewChild('dropdown_1') dropdown_1! : ElementRef;

  constructor(
    private mService: MessageService,
    private tSerivce: ThreadService
    ) { }

  ngOnInit(): void {
    if(!this.isProject){
      this.routeParams = {
        'customerId': this.thread.customerId,
      };
    } else {
      this.routeParams = {
        'customerId': this.thread.customerId,
        'projectId': this.thread.project_id,
      };
    }
    this.thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    for(let user of this.users){
      if(user.userId == Number(localStorage.getItem('user_id'))){
        this.currentUser = user;
      }
      for(let msg of this.thread.messages){
        if(msg.userId == user.userId){
          msg.user = user;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if(this.inputCustomer && this.customer) this.inputCustomer.nativeElement.value = this.form.controls['customer'].value.company_name;
  }

  ngOnDestroy(): void {
  }

  getThreadTitle(thread: Thread): string {
    if(thread.messages?.length > 0){
      thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      return thread.messages[0].message;
    }
    return '';
  }

  calcHeightTextField(value: string): number{
    let numOfLineBreaks = (value.match(/\n/g) || []).length;
    let newHeight = 20 + numOfLineBreaks * 30;
    return newHeight;
  }

  dissolve() {
    this.dissolveEmitter.emit(this.thread);
  }

  createMessage(msg: string, close: boolean) {
    let message = new Message(0,this.thread.threadId,Number(localStorage.getItem('user_id')),msg, new Date().getTime(),"text");
    message.user = this.currentUser;
    this.mService.createMessage(message).subscribe((thread) => {
      if(thread){
        for(let msg of thread.messages){
          msg.threadId = this.thread.threadId;
          for(let user of this.users){
            if(msg.userId == user.userId) msg.user = user;
          }
        }
        this.thread.messages = thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
        this.thread.subscribe = true;
        setTimeout(() => {
          let list = document.getElementById('ticket_answer_list');
          if(list) list.scrollTop = list.scrollHeight;
        }, 100);
        const content = document.getElementById('contenteditable');
        if(content) content.innerText = '';
        if(close) this.successCreateMessageAndCloseEmitter.emit(this.thread);
        else this.successCreateMessageEmitter.emit(this.thread);
      }
    })
  }

  selectionSubscribe(sub: boolean){
    if(sub){
      this.tSerivce.subscribe(this.thread.threadId).subscribe(() => {
        this.thread.subscribe = true;
      })
    } else {
      this.tSerivce.unsubscribe(this.thread.threadId).subscribe(() => {
        this.thread.subscribe = false;
      })
    }
  }

  isAuthorized(): boolean {
    if(this.thread.userId == this.currentUser.userId) return true;
    else false;
  }
}
