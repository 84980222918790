import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { ErrorHelper } from 'src/app/utils/ErrorHelper';

@Component({
  selector: 'app-dialog-edit-ticket',
  templateUrl: './dialog-edit-ticket.component.html',
  styleUrls: ['./dialog-edit-ticket.component.scss']
})
export class DialogEditTicketComponent implements OnInit {
  @Output() closeTicketEditEmitter = new EventEmitter<any>();
  @Input() ticket: Thread;
  public ticketForm: FormGroup;

  public submitted = false;
  public selectTypes = [
    { label: 'Todo', value: 1 },
    { label: 'Feature', value: 2 },
    { label: 'Bug', value: 3 },
    { label: 'Frage', value: 4 },
    { label: 'Doku', value: 5 }
  ];
  public selectedType: number = this.selectTypes[0].value;

  constructor(
    public formbuilder: FormBuilder, 
    private ticketService: ThreadService, 
  ) {}

  ngOnInit(): void {
    this.selectedType = this.ticket.type;              
    this.ticketForm = this.formbuilder.group({
      thread_typ: [this.ticket.type, Validators.required],
    });
  }

  updateTicket() {
    this.submitted = true;
    if(this.ticketForm.invalid) return;
    const updatedThreadTyp = this.ticketForm.get('thread_typ').value;
    this.ticket.type = updatedThreadTyp;
    this.ticketService.updateThread(this.ticket).subscribe(response => {
      if (response) {
        this.closeTicketEditEmitter.emit();
      }
    }, error => {
      console.error('Error updating thread type:', error);
    });
  }

  public onSelectType(type: { label: string, value: number }): void {
    this.selectedType = type.value;
    this.ticketForm.get('thread_typ')?.setValue(type.value);
  }
  
  close() {
    this.closeTicketEditEmitter.emit();
  }
}
