import { Message } from "../Message";

export class MessageFactory {
    static jsonFactory(rawBody: any): Message[] {
        const messages: Message[] = [];        
        for(let rawMessage of rawBody){
            messages.push(this.jsonFactoryOne(rawMessage));
        }
        return messages;
    }

    static jsonFactoryOne(rawMessage: any): Message {
        return new Message(
            rawMessage.message_id,
            rawMessage.thread_id,
            rawMessage.user_id,
            rawMessage.message,
            rawMessage.timestamp,
            rawMessage.attachment_type,
            rawMessage?.attachment
        );
    }
}