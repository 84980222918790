import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";

export class TicketForm {

  public static getTicketForm(formBuilder: FormBuilder): FormGroup {
    let form = formBuilder.group({
      subject: [null, [Validators.required, Validators.minLength(2)]],
      message: [null, [Validators.required, Validators.minLength(2), TicketForm.imageValidator]],
      thread_typ: [1,Validators.required]
    });
    return form;
  }

  private static imageValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    //console.log('Validator value:', value);
    const hasImage = /<img\s+[^>]*src="[^"]*"[^>]*>/i.test(value);
    const hasValidText = value.replace(/<[^>]*>/g, '').trim().length >= 2;
    if (hasValidText || hasImage) {
      return null;
    }
    return { messageWithImage: true };
  }
}
